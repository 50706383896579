import axios from 'axios';
import "bootstrap/dist/css/bootstrap.min.css";
import React, { Suspense, useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner'; 
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Navigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import './App.css';
import Main from './Main/main';
import { appleID, userID } from './Reducers/userReducer';
import { Base64 } from 'js-base64';

function App() {
  const dispatch = useDispatch();
  const authentication = useSelector((state) => state.user?.userID);

  const fetchAllTokens = async (credentials) => {

    console.log('[fetchAllTokens] Fetching all tokens with credentials:', credentials);
    console.log('18');

    try {
      const response = await fetch('https://b0wpxw4g-5000.asse.devtunnels.ms/get_tokens', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(credentials)
      });
      const data = await response.json();

      console.log('[fetchAllTokens] Response:', data);
      console.log('17');
      
      if (data.dat_user_access_token && data.direct_token && data.truckstop_token) {

        console.log('[fetchAllTokens] Storing tokens to localStorage');
        console.log('16');

        // Store each token to localStorage
        localStorage.setItem('dat_user_access_token', data.dat_user_access_token);
        localStorage.setItem('direct_token', data.direct_token);
        localStorage.setItem('truckstop_token', data.truckstop_token);
        localStorage.setItem('trucker_token', data.truckerpath_token);
        localStorage.setItem('direct-api-key', data.direct_api_key);

        // Store credentials with the fetched dat_token
        localStorage.setItem('credentials', JSON.stringify({ ...credentials, access_token: data.dat_user_access_token }));
      } else {
        console.error('[fetchAllTokens] Failed to fetch one or more tokens.');
      }
    } catch (err) {
      console.error('[fetchAllTokens] Error:', err);
    }
  };

  const fetchDatToken = async () => {

    console.log('[fetchDatToken] Fetching DAT token');
    console.log('15');

    try {
      let datCredentials = localStorage.getItem('datCredentials');

      console.log('[fetchDatToken] Retrieved datCredentials from localStorage:', datCredentials);
      console.log('14');
      
      if (!datCredentials) {

        console.log('[fetchDatToken] No credentials found, using default credentials');
        console.log('13');

        const DAT_CREDENTIALS1 = {
          "username": "thomas@jakebrakelogistics.com",
          "username_organization": "adrian@jakebrakelogistics.com",
          "password_organization": "Flyingfish69y2k",
          "access_token": "", // This will be populated with the access token
        };
  
        // Save the default credentials in localStorage
        localStorage.setItem('datCredentials', JSON.stringify(DAT_CREDENTIALS1));
        datCredentials = JSON.stringify(DAT_CREDENTIALS1); // Ensure we work with the string version
  
        console.log('[fetchDatToken] Default credentials set in localStorage');
        console.log('12');

      }
  
      // Try to parse the credentials from localStorage, handle errors if invalid
      let parsedCredentials;
      try {
        parsedCredentials = JSON.parse(datCredentials);

        console.log('[fetchDatToken] Parsed credentials:', parsedCredentials);
        console.log('11');

      } catch (parseError) {
        console.error('[fetchDatToken] Error parsing JSON from localStorage:', parseError);
        return; // Exit the function if there's an error parsing the credentials
      }
  
      // Step 1: Manually set the Organizational Access Token (since it's already available)
      const organizationalAccessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjEsImV4cCI6MTczNDA0MDc0NX0.KgSPoKO3NnlcT-3FPAjfKfvSBa_OI5oU1BNeZKVNvdU'; // The provided token
      parsedCredentials.access_token = organizationalAccessToken; // Store the token in datCredentials
  
      // Save the updated credentials back to localStorage
      localStorage.setItem('datCredentials', JSON.stringify(parsedCredentials));
  
      console.log('[fetchDatToken] Organizational Access Token set in datCredentials:', parsedCredentials.access_token);
      console.log('10');
  
      // Step 2: Use Organizational Token to fetch Individual Access Token
      const userResponse = await fetch('https://identity.api.dat.com/access/v1/token/user', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${parsedCredentials.access_token}`, // Use the organizational access token
        },
        body: JSON.stringify({
          username: parsedCredentials.username,
        }),
      });
  
      if (!userResponse.ok) {
        console.error('[fetchDatToken] Failed to fetch individual access token');
        throw new Error('Failed to fetch individual access token');
      }
  
      const userData = await userResponse.json();
      
      console.log('[fetchDatToken] Individual Access Token:', userData.accessToken);
      console.log('9');
  
      if (userData.accessToken) {

        console.log('[fetchDatToken] Storing Individual Access Token to localStorage');
        console.log('8');

        localStorage.setItem('dat_token', userData.accessToken);
  
        // Update the datCredentials with the fetched token and save it
        parsedCredentials.access_token = userData.accessToken;
        localStorage.setItem('datCredentials', JSON.stringify(parsedCredentials)); // Update localStorage with the new token
      } else {
        console.error('[fetchDatToken] Failed to fetch Individual Access Token');
      }
  
    } catch (err) {
      console.error('[fetchDatToken] Error:', err);
    }
  };
  
  
  

  const fetchTruckstopToken = async () => {

    console.log('[fetchTruckstopToken] Fetching Truckstop token');
    console.log('7');

    try {
      const response = await fetch('https://b0wpxw4g-5000.asse.devtunnels.ms/truckstop_token', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(JSON.parse(localStorage.getItem('trucksCredentials')))  // Corrected to ensure it's a proper JSON string
      });
      const data = await response.json();

      console.log('[fetchTruckstopToken] Response:', data);
      console.log('6');

      if (data.access_token) {

        console.log('[fetchTruckstopToken] Storing Truckstop token to localStorage');
        console.log('5');

        localStorage.setItem('trucks_token', data.access_token);
      } else {
        console.error('[fetchTruckstopToken] Failed to fetch Truckstop token:', data.message || 'Unknown error');
      }
    } catch (err) {
      console.error('[fetchTruckstopToken] Error:', err);
    }
  };

  const isTokenExpired = (token) => {

    console.log('[isTokenExpired] Checking token expiration:', token);
    console.log('4');

    if (!token) return true; // No token provided

    try {
      const decoded = JSON.parse(atob(token.split('.')[1]));
      const exp = decoded.exp * 1000; // Convert to milliseconds
      return Date.now() > exp;
    } catch (err) {
      console.error('[isTokenExpired] Error decoding token:', err);
      return true; // Assume expired if an error occurs
    }
  };

  const getAuth = async () => {

    console.log('[getAuth] Checking authentication status');
    console.log('3');

    if (localStorage.getItem('authentication')) {
      const token = JSON.parse(localStorage.getItem('authentication'))?.token;
      if (isTokenExpired(token)) {

        console.log('[getAuth] Token expired, clearing authentication data');
        console.log('2');

        clearAuthData();
        return <Navigate to='/' />;
      } else {
        const user = JSON.parse(localStorage.getItem('authentication'))?.user;

        console.log('[getAuth] Authentication valid, user:', user);
        console.log('1');

        dispatch(userID(user));
        localStorage.setItem('id', user.id);
        
        if (user?.role === 'admin') {
          // Fetch all tokens if the user is an admin
          await fetchAllTokens(JSON.parse(localStorage.getItem('credentials')));
          setInterval(() => fetchAllTokens(JSON.parse(localStorage.getItem('credentials'))), 300000);
        } 
        // else {
        //   // Fetch specific tokens for non-admin users
        //   // await fetchTruckstopToken();
        //   await fetchDatToken();
        //   setInterval(() => {
        //     // fetchTruckstopToken();
        //     fetchDatToken();
        //   }, 300000);
        // }
        return <Navigate to='/dashboard' />;
      }
    } else {

      console.log('[getAuth] No authentication found, clearing data');
        console.log('20');

      clearAuthData();
      return <Navigate to='/' />;
    }
  };

  const clearAuthData = () => {

    console.log('[clearAuthData] Clearing all authentication data');
    console.log('19');

    dispatch(userID([]));
    localStorage.clear();
  };

  useEffect(() => {
    getAuth();
  }, []);

  return (
    <BrowserRouter>
      <div className="App">
        <Suspense fallback={<Spinner animation="border" />}>
          <Main />
        </Suspense>
      </div>
    </BrowserRouter>
  );
}

export default App;
