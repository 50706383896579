import Papa from 'papaparse';
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import pdf from '../Assets/Bulk.pdf';
import '../Assets/post_bulk.css';
import { Bulk } from "../Reducers/userReducer";
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Autocomplete, Checkbox, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Button, Card, Col, Container, Row, Spinner } from "react-bootstrap";
import Draggable from 'react-draggable';
function PaperComponent(PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...PaperProps} />
    </Draggable>
  );
}
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const BulkPost = () => {
    const [file, setFile] = React.useState(null);
    const [fileName, setFileName] = React.useState('');
    const [fileDetails, setFileDetails] = React.useState(null);
    const [isUploading, setIsUploading] = React.useState(false);
    const [uploadStatus, setUploadStatus] = React.useState(null);
    const [dat_open, setDat_open] = React.useState(!localStorage.getItem('token'));
    const [dat_token, setDat_token] = React.useState('');

    const bulk = useSelector((state) => state?.user?.bulk);
    const dispatch = useDispatch();

    const postFile = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            setFileName(selectedFile.name);
            setFileDetails(`File size: ${(selectedFile.size / 1024).toFixed(2)} KB`);
            // Don't trigger posting, just set file state for now.
            setFile(null); // Clear any previously loaded file.
        }
    };

    const postFiles = async (event) => {
        event.preventDefault();

        if (!file) {
            // Ensure there is a file before trying to post.
            setUploadStatus("No file selected.");
            return;
        }

        setIsUploading(true); // Show the uploading progress
        setUploadStatus("Uploading file... Please wait.");
        
        try {
            const bearerToken = localStorage.getItem('token');
            const apiUrl = 'https://freight.api.dat.com/posting/v2/loads/tasks';

            const requestBody = {
                type: "CREATE_LOAD_POSTINGS_TASK",
                arguments: {
                    postings: file,
                },
            };

            const apiResponse = await axios.post(apiUrl, requestBody, {
                headers: {
                    Authorization: `Bearer ${bearerToken}`,
                    'Content-Type': 'application/json',
                },
            });

            setUploadStatus("File uploaded successfully!");
            dispatch(Bulk(false)); // Reset after posting
        } catch (error) {
            setUploadStatus("Error uploading file. Please try again.");
            dispatch(Bulk(false));
        } finally {
            setIsUploading(false);
        }
    };

    const handleCloseToken = () => {
        setDat_token('');
        setDat_open(false);
        dispatch(Bulk(false));
    };

    const submitToken = (e) => {
        e.preventDefault();
        localStorage.setItem('token', dat_token);
        setDat_open(false);
        dispatch(Bulk(true));
    };

    return (
        <>
            {localStorage.getItem('token') ? (
                <div className="centered-div">
                    <div className="containers">
                        <h1>Create Bulk Posts</h1>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                            <p style={{ display: 'inline-flex' }}>
                                Download the multiple shipments <a href="https://b0wpxw4g-5000.asse.devtunnels.ms/dashboard/post-bulk/csv" style={{ margin: '0.4rem', textDecoration: 'none' }}>CSV template</a> for an example of the format requirements.
                            </p>
                        </div>
                        <div>
                            <p>The CSV template contains example shipments. If you use the sample file to create your own</p>
                            <p>file make sure to remove all the included examples. Learn more about <a href={pdf} target="_blank">using the CSV file.</a></p>
                        </div>
                        <form id="csvForm" method="POST" onSubmit={postFiles}>
                            <label htmlFor="csvFile" className="custom-file-label">Choose CSV File</label>
                            <input
                                type="file"
                                id="csvFile"
                                name="csv"
                                className="custom-file-input"
                                accept=".csv"
                                onChange={postFile}
                                required
                            />
                            {fileName && (
                                <div className="file-info">
                                    <p>Selected File: {fileName}</p>
                                    <p>{fileDetails}</p>
                                </div>
                            )}
                            <button
                                type="submit"
                                className="custom-upload"
                                disabled={isUploading || !fileName} // Disable if uploading or no file selected
                            >
                                Upload
                            </button>
                        </form>

                        {uploadStatus && (
                            <div className="upload-status">
                                <p>{uploadStatus}</p>
                                {isUploading && <Spinner animation="border" />}
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <React.Fragment>
                    <Dialog
                        open={dat_open}
                        onClose={handleCloseToken}
                        PaperComponent={PaperComponent}
                        aria-labelledby="draggable-dialog-title"
                    >
                        <DialogActions sx={{ height: '2vh' }}>
                            <HighlightOffIcon
                                style={{ cursor: 'pointer', width: '5vw', height: '5vh', position: 'absolute', top: 15 }}
                                onClick={handleCloseToken}
                            />
                        </DialogActions>
                        <DialogContent>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <form onSubmit={submitToken}>
                                    <FormControl sx={{ m: 1, width: '35ch' }} xs={12} sm={12} md={12} xl={12}>
                                        <TextField
                                            id="standard-multiline-static"
                                            label="DAT Token"
                                            variant="outlined"
                                            name="token"
                                            value={dat_token}
                                            onChange={(e) => setDat_token(e.target.value)}
                                        />
                                    </FormControl>
                                    <p>If you don't have a token, generate it on the settings page</p>
                                    <Button type="submit">Show Loads</Button>
                                </form>
                            </Box>
                        </DialogContent>
                    </Dialog>
                </React.Fragment>
            )}
        </>
    );
};



export default BulkPost;
